:root {
  /* Background */
  --main-bg-color: #2f80ed;
  --primary-bg-color: #2d9cdb;
  --white-bg-color: #ffffff;
  --yellow-bg-color: #f2c94c;
  --lightgrey-bg-color: rgba(51, 51, 51, 0.05);

  /* Text */
  --main-text-color: #ffffff;
  --primary-text-color: #2f80ed;
  --grey-1-color: #333333;
  --font-weight: 600;
}

.shop-page {
}
.shop-nav-container {
}

.shop-nav {
  position: relative;
  display: flex;
  justify-content: space-between;
}

/* Shop filter */
.shop-nav .filter {
  width: 150px;
}
.shop-nav .filter.left-filter select.uk-select {
  border-radius: 5px;
}
.shop-nav .filter.right-filter {
  text-align: right !important;
}
.shop-nav .filter.right-filter button.uk-button {
  border-radius: 5px;
  background-color: transparent;
  text-transform: none;
  border: 1px solid #e5e5e5;
}
.shop-nav .filter.right-filter button.uk-button:hover {
  background-color: var(--lightgrey-bg-color);
}
.shop-nav .filter.right-filter span.icon {
  margin-right: 10px;
}

/* Shop category container */
.shop-nav .shop-category-container {
  position: relative;
}
.shop-category-container button.left,
.shop-category-container button.right {
  position: absolute;
  height: 40px;
  width: 40px;
  border-radius: 20px;
  outline: none;
  background-color: transparent;
  border: 0;
  transition: background-color 0.3s ease-in;
  z-index: 10;
}
.shop-category-container button.left:hover,
.shop-category-container button.right:hover {
  background-color: var(--lightgrey-bg-color);
}
.shop-category-container button.left {
  top: 50%;
  left: -50px;
  transform: translateY(-50%);
}
.shop-category-container button.right {
  top: 50%;
  right: -50px;
  transform: translateY(-50%);
}

/* Shop category list */
.shop-nav .shop-category-list {
  display: flex;
  justify-content: flex-start;
  list-style: none;
  width: 100%;
  max-width: 50vw;
  overflow-x: scroll;
  box-sizing: content-box;
  margin: 0;
}
.shop-nav ::-webkit-scrollbar {
  display: none;
}
.shop-nav .shop-category-list .category-item {
  margin: 2px;
}
.shop-nav .shop-category-list button.category {
  color: var(--grey-1-color);
  border-radius: 5px;
  text-transform: none;
  padding-left: 15px !important;
  padding-right: 15px !important;
  width: 100%;
  white-space: nowrap;
  background-color: transparent;
  transition: 0.3s background-color ease-in;
}
.shop-nav .shop-category-list .category-item.uk-active button.category {
  background-color: var(--lightgrey-bg-color);
  font-weight: var(--font-weight);
}
.shop-nav .shop-category-list .category-item:hover button.category {
  background-color: var(--lightgrey-bg-color);
}

/* Shop filter */
.shop-filter-content-container {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.3s ease-in;
}
.shop-filter-content {
  overflow: hidden;
  margin-top: 20px;
  border-radius: 10px;
  margin-bottom: 50px;
}
.shop-filter-content label {
  font-weight: var(--font-weight);
}
.shop-filter-content input,
.shop-filter-content input:focus {
  border-radius: 5px;
  background-color: var(--lightgrey-bg-color);
  border: 0;
}

/* Single product */
.single-product .product-image-column .product-image {
  border-radius: 10px;
  height: 70vh;
  max-height: 500px !important;
  background-color: var(--lightgrey-bg-color);
  overflow: hidden;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.single-product .product-image-column .product-image img {
  object-fit: contain;
  -o-object-fit: contain;
  object-position: center;
  -o-object-position: center;
}
.single-product .product-image-column .product-image .uk-card-badge {
  font-weight: var(--font-weight);
  padding: 5px 8px;
}
.single-product .product-details-column .product-details .uk-card-title {
  font-weight: var(--font-weight);
  font-size: 2.4rem;
}
.single-product .product-details-column .product-details .price {
  font-family: "Delius", cursive;
  font-size: 1.2rem;
  font-weight: var(--font-weight);
  padding: 10px;
  width: max-content !important;
  color: #d9b444;
  background-color: #fdf6e1;
  border-radius: 5px;
}
.single-product .product-details-column .product-details .description {
  color: var(--grey-1-color);
}
.single-product .product-details-column .product-details input {
  border-radius: 5px;
  width: 70px;
  margin-bottom: 20px;
}

/* Reviews */
.reviews .uk-tab {
  margin-bottom: 0 !important;
}
.reviews .uk-tab > li > a {
  color: var(--primary-text-color) !important;
  text-transform: none;
  font-weight: 500;
  border-bottom: 0;
  padding-top: 10px;
  padding-bottom: 0;
}
.reviews .uk-tab > li > a::after {
  content: "";
  display: block;
  margin: 0 auto;
  width: 0;
  padding-top: 10px;
  border-bottom: 3px solid var(--primary-text-color);
  transition: 0.3s width ease-in;
}
.reviews .uk-tab > li.uk-active > a::after {
  width: 60px;
}
.reviews-write {
  margin-top: 40px;
}
.reviews-write input,
.reviews-write textarea {
  border-radius: 5px;
}

.temporary-shop-category {
  flex-wrap: nowrap;
  overflow-x: auto;
  padding-bottom: 5px;
  &::before {
    content: none;
  }
  li {
    a {
      text-transform: none;
      font-weight: 600;
    }
  }
}
