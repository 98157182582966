@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

:root {
  /* Background */
  --main-bg-color: #2f80ed;
  --primary-bg-color: #2d9cdb;
  --white-bg-color: #ffffff;
  --yellow-bg-color: #f2c94c;

  /* Text */
  --main-text-color: #ffffff;
  --primary-text-color: #2f80ed;
  --grey-1-color: #333333;
  --font-weight: 600;
  --grey-2-color: #4F4F4F;
  --grey-3-color: #828282;
  --grey-6-color: #F2F2F2;

  --danger-bg-color: #eb5757;
  --danger-bg-hover-color: #ee395b;
}


* {
  box-sizing: border-box;
}

html,
body {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

body {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

h1,
h2,
h3 {
  font-family: 'Roboto', sans-serif;
  /* font-family: 'Proza Libre', sans-serif; */
  /* font-family: "Bebas Neue"; */
  font-weight: var(--font-weight);
  color: var(--grey-2-color);
  letter-spacing: .035rem;
}
