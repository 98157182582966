:root {
  /* Background */
  --main-bg-color: #2f80ed;
  --primary-bg-color: #2d9cdb;
  --white-bg-color: #ffffff;
  --yellow-bg-color: #f2c94c;

  /* Text */
  --main-text-color: #ffffff;
  --primary-text-color: #2f80ed;
  --grey-1-color: #333333;
  --font-weight: 600;
  --grey-2-color: #4f4f4f;
  --grey-3-color: #828282;
  --grey-6-color: #f2f2f2;

  --danger-bg-color: #eb5757;
  --danger-bg-hover-color: #ee395b;
}

.uk-section-muted {
  background-color: var(--grey-6-color);
}

.section-heading {
  font-size: 1.6rem !important;
  text-align: left !important;
  color: var(--grey-2-color);
}

/* nav container */
.nav-container {
  padding-left: 10%;
  padding-right: 10%;
  background-color: var(--main-bg-color) !important;
  .nav-bar {
    background-color: var(--main-bg-color) !important;
    height: 60px;
    .uk-navbar-toggle {
      color: var(--white-bg-color);
      padding: 0;
    }
    .uk-navbar-item {
      min-height: 60px !important;
    }
    .uk-navbar-toggle {
      min-height: 60px !important;
    }
    .uk-navbar-nav > li > a {
      height: 100%;
      min-height: auto !important;
      color: var(--main-text-color) !important;
      text-transform: none;
      font-weight: 500;
    }
    .logo {
      height: 30px;
      img {
        object-fit: contain;
        -o-object-fit: contain;
        height: 30px;
      }
    }
    .icon {
      margin-right: 5px;
      margin-left: 5px;
    }
    .uk-dropdown {
      top: 50px !important;
    }
    .round-btn {
      background-color: transparent;
      padding: 10px;
      border: 0;
      outline: none;
      color: var(--white-bg-color);
      border-radius: 30px;
      transition: background-color 0.3s;
      margin-left: 2px;
      margin-right: 2px;
      &:hover {
        background-color: var(--primary-bg-color);
      }
    }
  }
}

#offcanvas-nav-primary {
  .uk-nav {
    a {
      font-size: 1.2rem;
    }
  }
}

/* Main Banner */
.service-banner .banner-background {
  min-height: calc(100vh - 60px);
  background-attachment: fixed;
}
// position: relative;
button.sliderBtn {
  position: absolute;
  top: 80px;
  transform: translateY(-50%);
  background-color: var(--grey-1-color);
  height: 30px;
  width: 30px;
  border-radius: 15px;
  border: 0;
  outline: none;
  color: var(--grey-6-color);

  z-index: 100;
  &.left {
    left: 10px;
  }
  &.right {
    right: 10px;
  }
}

/* Mid Nav */
.mid-nav {
  background-color: var(--white-bg-color);
  // overflow: hidden;

  @media only screen and (max-width: 767px) {
    ul {
      &::before {
        content: none;
      }
    }
    padding-left: 10px;
    padding-right: 10px;
    .uk-tab {
      margin-left: 0;
      .mid-service-item {
        padding: 0;
        a {
          max-width: max-content;
        }
      }
    }
  }
  .uk-tab {
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    width: 100%;
    margin-bottom: 0 !important;

    .mid-service-item {
      min-width: 100px;
      &::after {
        content: "";
        display: block;
        margin: 0 auto;
        width: 0;
        padding-top: 10px;
        border-bottom: 3px solid var(--primary-text-color);
        transition: 0.3s width ease-in;
      }
      &.uk-active::after {
        width: 100%;
      }

      a {
        color: var(--primary-text-color) !important;
        text-transform: none;
        font-weight: 500;
        border-bottom: 0;
        padding-top: 10px;
        padding-bottom: 0;
        text-align: center;
        margin: auto;

        .service-icon {
          display: block;
          font-size: 1.4rem;
          padding: 5px;
        }
      }
    }
  }
}

/* Service intro, Shop intro */
.shop-intro .uk-card-title,
.service-intro .uk-card-title {
  font-size: 3.2rem;
  font-weight: var(--font-weight);
  @media only screen and (max-width: 767px) {
    font-size: 2rem;
  }
}
.shop-intro .buttons,
.service-intro .buttons {
  margin-top: 30px;
  margin-bottom: 30px;
}
.shop-intro .buttons .uk-button,
.service-intro .buttons button {
  text-transform: none;
  border-radius: 2px;
}
.service-intro .buttons button:first-child {
  margin-right: 20px;
}
.service-intro .uk-button-lighter-primary {
  color: var(--primary-text-color);
  text-decoration: underline;
  font-weight: var(--font-weight);
}

/* Shop intro */
.shop-intro .border-radius {
  border-radius: 30px;
}
.shop-intro .product-list {
  margin-top: 60px;
}

/* Other servces */
.other-services .section-heading {
  font-size: 1.8rem;
}

/* Cards  */
.maintenance-service-card {
  transition: 0.3s all ease-in;
  img {
    height: 220px;
    border-radius: 2px;
    width: 100%;
    object-fit: cover;
    -o-object-fit: cover;
    object-position: center center;
    -o-object-position: center center;
  }
  .uk-overlay {
    background-color: rgba(47, 128, 237, 0.8);
    color: var(--white-bg-color);
    padding: 20px 20px;
    border-radius: 2px;
    transition: 0.3s all ease-in;
  }

  &.uk-link-toggle {
    .uk-link-heading {
      margin: 0;
    }
    &:hover {
      .uk-link-heading {
        color: var(--main-text-color) !important;
      }
      .uk-overlay {
        background-color: var(--main-bg-color);
      }
    }
  }
  .pos-relative {
    position: relative;
  }
}

.news-letter-card {
  h3.uk-card-title {
    font-size: 1.2rem;
    font-weight: var(--font-weight);
    color: var(--white-bg-color);
    margin-top: 10px;
  }
  form {
    display: flex;
    .subscribe-input {
      width: auto;
      min-width: 100px;
    }
    button {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}

.card-d img {
  height: 200px;
  width: 100%;
  object-fit: cover;
  -o-object-fit: cover;
  object-position: center center;
  -o-object-position: center center;
}

.card-b h3.uk-card-title,
.card-d h3.uk-card-title {
  font-size: 1.2rem;
  font-weight: var(--font-weight);
  color: var(--grey-1-color);
}
.card-b h3.uk-card-title {
  margin-top: 10px;
}
.card-b .icon {
  font-size: 1.4rem;
  color: var(--grey-1-color);
}
.card-b .uk-link {
  // font-size: 1.4rem;
  color: var(--grey-1-color) !important;
}
.card-c .uk-link {
  border-radius: 2px;
  text-decoration: none;
}
.card-c .uk-link .uk-h4 {
  background-color: var(--white-bg-color);
  padding: 20px 30px;
  font-weight: 500;
  transition: 0.2s all ease-in;
}
.card-c .uk-link:hover .uk-h4 {
  background-color: var(--main-bg-color);
  color: var(--main-text-color);
}
.card-d .uk-card-badge {
  font-family: "Delius", cursive;
  font-weight: var(--font-weight);
  padding: 5px 8px;
  background-color: var(--yellow-bg-color);
}
.card-d .uk-card-media-top {
  overflow: hidden;
}
.card-d .uk-card-media-top img {
  border-radius: 2px;
  object-fit: contain;
  -o-object-fit: contain;
  transform: scale(1);
  transition: 0.2s transform ease-in;
}
.card-d:hover img {
  transform: scale(1.2);
}
.card-d button {
  /* border-radius: 5px; */
  text-transform: none;
}

/* Booking page */
/* Booking tabs */
.booking-tabs .uk-tab {
  margin-bottom: 0 !important;
}
.booking-tabs .uk-tab > li > a {
  color: var(--primary-text-color) !important;
  text-transform: none;
  font-weight: 500;
  border-bottom: 0;
  padding-top: 10px;
  padding-bottom: 0;
}
.booking-tabs .uk-tab > li > a::after {
  content: "";
  display: block;
  margin: 0 auto;
  width: 0;
  padding-top: 10px;
  border-bottom: 3px solid var(--primary-text-color);
  transition: 0.3s width ease-in;
}
.booking-tabs .uk-tab > li.uk-active > a::after {
  width: 30px;
}

/* Booking content */
.booking-content .uk-heading-line {
  color: var(--grey-3-color) !important;
  text-transform: uppercase;
  font-size: 1rem;
  margin-bottom: 0px;
  margin-top: 30px;
}

/* Booking regularity */
.booking-regularity {
  .regularity {
    .promo {
      margin-bottom: 4px;
    }
    label {
      position: relative;
      display: flex;
      line-height: 38px;
      input {
        display: none;
        &:checked ~ .one-time {
          background-color: var(--main-bg-color);
          color: var(--white-bg-color);
        }
        &:not(:checked) ~ .regular {
          background-color: var(--main-bg-color);
          color: var(--white-bg-color);
        }
      }
    }
    span {
      width: 50%;
    }

    .one-time {
      text-transform: none;
      white-space: nowrap;
      background-color: transparent;
      transition: 0.3s all ease-in;
      padding-left: 15px;
      padding-right: 15px;
    }
    .regular {
      text-transform: none;
      white-space: nowrap;
      background-color: transparent;
      transition: 0.3s all ease-in;
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}

// .booking-regularity .regularity label input:checked ~ .one-time {
//   background-color: var(--main-bg-color);
//   color: var(--white-bg-color);
//   border-color: var(--main-bg-color);
// }

// .booking-regularity .regularity label input:not(:checked) ~ .regular {
//   background-color: var(--main-bg-color);
//   color: var(--white-bg-color);
//   border-color: var(--main-bg-color);
// }

.booking-regularity .date input,
.booking-regularity .time input {
  border-radius: 2px;
}

/* Booking task item */
.booking-task-item {
  position: relative;
  display: block;
  input {
    display: none;

    &:checked {
      ~ .item-name p {
        color: var(--primary-text-color);
        font-weight: 600;
      }
      ~ .item-icon {
        background-color: #eaf2fd;
        border-color: var(--primary-text-color);
        .check {
          transform: scale(1);
        }
      }
    }
  }
  .item-icon {
    position: relative;
    height: 100px;
    width: 100px;
    padding: 15px;
    border: 3px solid var(--grey-3-color);
    border-radius: 50%;
    transition: 0.2s all ease-in;
    margin: auto;

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
      -o-object-fit: contain;
      object-position: center;
      -o-object-position: center;
    }

    .check {
      position: absolute;
      right: -10px;
      top: 20px;
      color: var(--white-bg-color);
      font-size: 0.8rem;
      line-height: 30px;
      transform: scale(0);
      transition: 0.2s transform ease-in;
      background-color: var(--main-bg-color);
      height: 30px;
      width: 30px;
      border-radius: 50%;
      text-align: center;
      align-items: center;
      z-index: 10;
    }
  }

  .item-name p {
    color: var(--grey-1-color);
    margin-top: 10px;
    transition: 0.2s all ease-in;
  }
}

/* Booking rooms */
.booking-input-item .item-container {
  display: inline-block;
  position: relative;
  text-align: left !important;
}
.booking-input-item .item-container label {
  font-size: 1rem;
}
.booking-input-item .item-container input {
  width: 100%;
  /* background-color: #F2F2F2; */
  border: 1px solid #e0e0e0;
  color: var(--primary-text-color);
  font-weight: var(--font-weight);
  outline: none;
  border-radius: 2px;
  height: 50px;
  text-align: center;
}
.booking-input-item .item-container input::-webkit-outer-spin-button,
.booking-input-item .item-container input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.booking-input-item .item-container button {
  position: absolute;
  background-color: transparent;
  border: 0;
  outline: none;
  color: var(--primary-text-color);
  height: 50px;
  width: 40px;
}
.booking-input-item .item-container button.minus-button {
  left: 0;
  bottom: 0;
}
.booking-input-item .item-container button.add-button {
  right: 0;
  bottom: 0;
}

/* Booking summary */
.booking-summary .label {
  font-weight: var(--font-weight);
  font-size: 0.9rem;
  margin-bottom: 0;
  color: var(--primary-text-color);
}
.booking-summary .value {
  font-weight: var(--font-weight);
  margin-top: 0;
  color: var(--grey-1-color);
}
.booking-summary {
  position: relative;
  @media only screen and (min-width: 960px) {
    position: sticky;
    top: 50px;
  }
  .selected-pro {
    display: flex;
    .thumbnail {
      width: 80px;
      height: 80px;
      margin-right: 10px;
      border-radius: 40px;
      border: 2px solid #828282;
      overflow: hidden;
      background-color: #828282;
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        -o-object-fit: cover;
        object-position: center;
        -o-object-position: center;
      }
    }
    .body {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }
  }
}

/* Cart */
.cart-item {
  position: relative;
  overflow: hidden;
  height: 100%;
}

.cart-item .image {
  height: 100px;
}
.cart-item .image img {
  height: 100%;
  object-fit: contain;
}
.cart-item .match h3 {
  font-size: 1.2rem;
  font-weight: var(--font-weight);
  margin-bottom: 10px;
}
.cart-item .match p {
  color: var(--grey-3-color);
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 100px;
  line-height: 25px;
}
.cart-item .match p.description {
  margin-top: 0;
}
.cart-item .match .price {
  position: absolute;
  top: 0;
  color: var(--yellow-bg-color);
  font-weight: var(--font-weight);
  font-size: 1.2rem;
}
.cart-item .match input {
  text-align: center;
  border-radius: 2px;
}
.cart-item .close-btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.cart-item .close-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--danger-bg-color);
  color: var(--white-bg-color);
  border: 0;
  margin: 0;
  padding: 0;
  border-radius: 100%;
  height: 30px;
  width: 30px;
  outline: none;
  margin: auto;
}
.cart-item .close-btn:hover,
.cart-item .close-btn:focus {
  background-color: var(--danger-bg-hover-color);
  cursor: pointer;
}
.v-h-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Help center */
.help-banner .banner-background::before {
  content: "";
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
}
.help-banner .uk-section {
  position: relative;
  z-index: 1;
}
.help-banner .title {
  font-size: 3.2rem;
  font-weight: var(--font-weight);
  color: var(--main-text-color);
}
.help-banner .search {
  margin-top: 30px;
}
.help-banner .search input {
  margin: 0 10px;
  border-radius: 2px;
  outline: none;
  width: 90%;
  max-width: 700px;
  font-size: 1rem;
}

// .help-content .category-item-accordian::before {
//   content: none;
// }

// .help-content .category-icon .icon {
//   height: 150px;
//   width: 150px;
//   background-color: #f2f2f2;
//   border-radius: 50%;
//   font-size: 2rem;
// }
// .help-content .category-icon h3 {
//   font-size: 3rem;
//   color: var(--primary-text-color);
//   line-height: 150px;
// }
// .help-content .category-content .title {
//   font-size: 2.2rem;
//   color: #4f4f4f;
// }
.help-content .category-content .description {
  font-weight: 300;
}

/*  */
.clients-review .review-item .uk-card {
  border-radius: 2px;
  margin-top: 20px !important;
  margin-bottom: 30px !important;
  box-shadow: none;
}
.clients-review .review-item:hover .uk-card {
  box-shadow: 0 14px 25px rgba(68, 52, 52, 0.16);
}
.clients-review .review-item .uk-card-media-top {
  padding-top: 20px;
}
.clients-review .review-item .uk-card-media-top img {
  height: 110px;
  width: 110px;
  object-fit: cover;
  border-radius: 50%;
}
.clients-review .review-item .uk-card-title {
  font-size: 1.2rem;
  font-weight: var(--font-weight);
  /* color: var(--grey-1-color); */
}

/* Corporate clients */
.corporate-clients .slider-controls,
.clients-review .slider-controls {
  display: block !important;
  top: 0;
  position: absolute;
  height: 25px;
  width: 25px;
  border-radius: 2px;
  background-color: #e0e0e0;
  line-height: 25px;
  text-align: center;
  color: #4f4f4f;
  font-size: 0.8rem;
}
.corporate-clients a.slider-controls.previous,
.clients-review a.slider-controls.previous {
  right: 30px;
}
.corporate-clients a.slider-controls.next,
.clients-review a.slider-controls.next {
  right: 0px;
}
.corporate-clients .company-item img {
  max-height: 100px;
  object-fit: contain;
  -o-object-fit: contain;
  object-position: center;
  -o-object-position: center;
}

/* Modal */
.modal {
  .modal-header {
    clear: both;
    .title {
      float: left;
      font-size: 1.6rem;
      span {
        color: var(--primary-text-color);
      }
    }
    .link {
      font-size: 0.9rem;
      font-weight: var(--font-weight);
      float: right;
      line-height: 2.6rem;
    }
  }
  .modal-content {
    clear: both;
    .modal-form {
      input,
      textarea {
        border-radius: 2px;
        background-color: #f2f2f2;
      }
      button.submit {
        border-radius: 2px;
        text-transform: none;
      }
    }
  }
}

/* Login Page */
.login-page .brand .sign-up-page .brand {
  margin-bottom: 50px;
}
.login-page .brand img,
.sign-up-page .brand img {
  width: 150px;
  object-fit: contain;
}
.login-page .login-card,
.sign-up-page .sign-up-card {
  margin: auto;
  max-width: 400px;
}
.login-page .login-card .uk-card-header .uk-card-title,
.sign-up-page .sign-up-card .uk-card-header .uk-card-title {
  font-weight: var(--font-weight);
}
.login-page .login-card .forgot-password {
  font-size: 0.8rem;
  font-weight: var(--font-weight);
}
.login-page .sign-in,
.sign-up-page .sign-up {
  text-transform: none;
}
.login-page .sign-up,
.sign-up-page .sign-in {
  margin-top: 40px !important;
}
.login-page .sign-up a,
.sign-up-page .sign-in a {
  font-weight: var(--font-weight);
}
.login-page .social-login button,
.sign-up-page .social-sign-up button {
  text-transform: none;
  margin: 5px;
}

/* Section heading */
.heading {
  font-weight: 600;
  color: var(--white-bg-color);
}

.heading span {
  color: var(--main-bg-color);
}

/* Page header */
.page-header {
  position: relative;
  .heading {
    font-size: 3.2rem;
    @media only screen and (max-width: 767px) {
      font-size: 2rem;
    }
  }
  .back-to-home {
    position: absolute;
    right: 10%;
    top: 10%;
    text-transform: none;
    border-radius: 2px;
  }
}

.heading-overlay {
  text-align: center;
  padding: 10px 20px;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 2px;
}

/* LAUNDRY CLEANING SECTION */
.laundry-cleaning-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  .laundry-card {
    display: flex;

    ul.content {
      color: var(--white-bg-color);
      min-width: auto;
      list-style: none;
      margin: 0;
      padding: 20px 30px;
      background-color: rgba(0, 0, 0, 0.4);
      @media only screen and (min-width: 600px) {
        min-width: 450px;
        color: var(--white-bg-color);
      }
    }
    .action {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px 30px;
      background-color: rgba(0, 0, 0, 0.6);
      transition: ease-in-out all 0.3s;
      a {
        color: var(--white-bg-color);
        font-weight: 600;
        font-size: 1.1rem;
        text-decoration: none;
        text-align: center;
        .span {
          font-weight: 400;
          font-size: 3rem;
        }
      }

      &:hover {
        background-color: #215baa;
      }
    }
  }
}

/* MATTRESS CLEANING CARD */
.price-cleaning-card {
  display: flex;
  margin-top: 15px;
  margin-bottom: 15px;
  width: 100%;

  .content {
    display: flex;
    flex-grow: 1;
    align-items: center;
    padding: 15px 30px;
    color: var(--white-bg-color);
    min-width: auto;
    background-color: rgba(0, 0, 0, 0.4);

    p {
      margin: 0;
    }

    @media only screen and (min-width: 600px) {
      min-width: 240px;
      color: var(--white-bg-color);
    }
  }

  .action {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 20px;
    background-color: rgba(0, 0, 0, 1);
    transition: ease-in-out all 0.3s;

    a {
      color: var(--white-bg-color);
      font-weight: 400;
      text-decoration: none;
      text-align: center;
    }

    &:hover {
      background-color: #215baa;
    }
  }
}

.mattress-cleaning,
.laundry-cleaning,
.sofa-cleaning,
.carpet-cleaning {
  position: relative;

  &:hover .uk-section::before {
    background-color: rgba(0, 0, 0, 0.8);
  }
  .uk-section {
    &::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.2);
      overflow: hidden;
      z-index: 0;
      transition: 0.2s ease-in all;
    }

    .uk-container {
      position: relative;
      z-index: 10;
    }
  }

  .cleaning-packages {
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 100%;
  }
}

.mattress-cleaning,
.sofa-cleaning,
.carpet-cleaning {
  .laundry-header {
    margin-bottom: 0;
  }
  // Format intro
  .mattress-intro,
  .sofa-intro,
  .carpet-intro {
    display: flex;
    flex-direction: column;
    justify-content: center;
    // Heading
    .heading {
      display: inline-block;
    }
    .service-description {
      font-weight: 400;
      color: var(--white-bg-color);
      margin-top: 30px;
      margin-bottom: 30px;
    }
    .bookBtn {
      padding: 15px 20px;
      line-height: 1;
      text-transform: none;
    }
  }
}

// ABOUT US PAGE
// COMPANY STATMENT SECTION
// ABOUT TEAM
.about-team {
  .our-pros,
  .our-team {
    position: relative;
  }
  .heading {
    color: var(--grey-1-color);
    span {
      color: var(--primary-text-color);
    }
  }
  .team-card {
    .uk-card-media-top {
      height: 250px;
      overflow: hidden;
      .cover {
        object-fit: cover;
        -o-object-fit: cover;
        object-position: center;
        -o-object-position: center;
      }
      img {
        min-height: 100%;
        min-width: 100%;
      }
    }
    .uk-card-footer {
      .name {
        color: var(--grey-1-color);
        font-size: 1rem;
        font-weight: 500;
        margin: 5px auto;
      }
      .role,
      .position {
        font-size: 0.8rem;
        margin: 5px auto;
      }
      .role {
        text-transform: capitalize;
      }
      .position {
        font-weight: 600;
        text-transform: uppercase;
      }
    }
  }

  .about-pro-card {
    background-color: transparent;
    .uk-card-media-top {
      height: 150px;
      width: 150px;
      border-radius: 50%;
      overflow: hidden;
      margin: 0 auto;
      img {
        object-fit: contain;
        object-position: center center;
      }
    }
    .name {
      color: var(--grey-1-color);
      font-size: 1rem;
      font-weight: 500;
      margin: 5px auto;
    }
  }

  // .pro-card {
  //   height: 150px;
  //   width: 150px;
  //   border-radius: 50%;
  //   overflow: hidden;
  //   margin: 0 auto;
  //   .uk-card-media-top {
  //     img {
  //       object-fit: contain;
  //       object-position: center center;
  //     }
  //   }
  //   .uk-card-footer {
  //     .name {
  //       color: var(--grey-1-color);
  //       font-size: 1rem;
  //       font-weight: 500;
  //       margin: 5px auto;
  //     }
  //     .role {
  //       font-size: 0.8rem;
  //       margin: 5px auto;
  //     }
  //   }
  // }

  a.slider-controls {
    position: absolute;
    top: 0;
    height: 25px;
    width: 25px;
    line-height: 25px;
    border-radius: 2px;

    font-size: 0.8rem;
    text-align: center;
    color: var(--white-bg-color);
    background-color: var(--grey-1-color);
    &.previous {
      right: calc(4% + 30px);
    }
    &.next {
      right: 4%;
    }
  }
}

.blog {
  .blog-finder {
    form {
      display: flex;
      justify-content: space-between;

      .search {
        max-width: 400px;
      }
      .filter {
        max-width: 200px;
      }
    }
  }

  .blog-card {
    .thumbnail {
      height: 250px;
      overflow: hidden;
      img {
        object-fit: contain;
        -o-object-fit: contain;
        object-position: center;
        -o-object-position: center;
      }
    }
    .content {
      .date {
        color: var(--primary-text-color);
        font-size: 0.8rem;
        margin-bottom: 0;
      }
      .title {
        font-size: 1.2rem;
        font-weight: 500;
        margin-top: 10px;
        margin-bottom: 10px;
      }
      .link {
        color: var(--primary-text-color);
      }
    }
  }
}

.internet {
  .internet-card {
    .content {
      .badge {
        display: inline;
        padding: 10px 15px;
        color: var(--grey-6-color);
        background-color: #27ae60;
        border-radius: 2px;
      }
      .title {
        font-size: 1.2rem;
        font-weight: 600;
      }
      .price {
        font-size: 2.4rem;
        font-weight: 600;
        span {
          font-size: 1rem;
        }
      }
      .description {
        color: var(--grey-3-color);
        margin-top: 5px;
        margin-bottom: 5px;
      }
    }
    .footer {
      padding: 0;
      button {
        display: block;
        width: 100%;
        padding: 10px 15px;
      }
    }
  }
}

.cleaning {
  .booking-form {
    // Tabs
    .booking-tabs {
      margin: auto;
      text-align: center;
      .uk-card {
        display: inline-block;
        padding: 2px;
      }
      ul.booking-category-tabs {
        margin: 0;
        &::before {
          content: none;
        }
        li {
          padding-left: 20px;
          padding-right: 20px;
          a {
            padding-top: 20px;
            padding-bottom: 5px;
          }
          &.uk-active {
            background-color: #eaf2fd;
          }
        }
      }
    }

    // Categories
    .booking-categories {
      position: relative;
      .heading {
        color: var(--grey-1-color);
      }

      a.slider-controls {
        position: absolute;
        top: 0;
        height: 25px;
        width: 25px;
        line-height: 25px;
        border-radius: 2px;
        font-size: 0.8rem;
        text-align: center;
        color: var(--white-bg-color);
        background-color: var(--main-bg-color);

        &.uk-invisible {
          visibility: visible !important;
          opacity: 0.4;
          background-color: var(--grey-3-color) !important;
        }

        &.previous {
          right: calc(0% + 30px);
        }

        &.next {
          right: 0%;
        }
      }

      .category-card {
        display: block;
        position: relative;
        padding: 5px;

        input {
          position: absolute;
          display: none;

          &:checked {
            ~ .card-name p,
            ~ .card-icon {
              font-weight: 600;
              color: var(--primary-text-color);
              border-color: var(--primary-text-color);
              .check {
                transform: scale(1);
              }
            }
          }
        }

        .card-icon {
          height: 120px;
          border: 3px solid var(--grey-3-color);
          border-radius: 10px;
          transition: 0.2s all ease-in;
          overflow: hidden;

          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            -o-object-fit: cover;
            object-position: center;
            -o-object-position: center;
          }

          .check {
            position: absolute;
            right: 0px;
            top: 0px;

            font-size: 0.8rem;
            color: var(--white-bg-color);
            text-align: center;
            align-items: center;
            transition: 0.2s transform ease-in;
            transform: scale(0);

            background-color: var(--main-bg-color);
            height: 30px;
            width: 30px;
            line-height: 30px;
            border-radius: 50%;

            z-index: 10;
          }
        }

        .card-name p {
          color: var(--grey-1-color);
          margin-top: 10px;
          margin-bottom: 10px;
          transition: 0.2s all ease-in;
        }
      }
    }

    // Quantity
    .category-quantity {
      .heading {
        color: var(--grey-1-color);
      }

      .quantity-list {
        .quantity-input-item {
          label {
            font-weight: 500;
            color: var(--grey-1-color);
          }
          input {
            border-radius: 5px;
            height: 50px;
          }
        }
      }
    }

    // Extras
    .booking-extras {
      position: relative;
      .heading {
        color: var(--grey-1-color);
      }

      a.slider-controls {
        position: absolute;
        top: 0;
        height: 25px;
        width: 25px;
        line-height: 25px;
        border-radius: 2px;

        font-size: 0.8rem;
        text-align: center;
        color: var(--white-bg-color);
        background-color: var(--main-bg-color);

        &.uk-invisible {
          visibility: visible !important;
          opacity: 0.4;
          background-color: var(--grey-3-color) !important;
        }

        &.previous {
          right: calc(0% + 30px);
        }

        &.next {
          right: 0%;
        }
      }
    }

    // Choose a pro
    .choose-a-pro {
      position: relative;
      .heading {
        color: var(--grey-1-color);
      }

      a.slider-controls {
        position: absolute;
        top: 0;
        height: 25px;
        width: 25px;
        line-height: 25px;
        border-radius: 2px;

        font-size: 0.8rem;
        text-align: center;
        color: var(--white-bg-color);
        background-color: var(--main-bg-color);

        &.uk-invisible {
          visibility: visible !important;
          opacity: 0.4;
          background-color: var(--grey-3-color) !important;
        }

        &.previous {
          right: calc(0% + 30px);
        }

        &.next {
          right: 0%;
        }
      }

      .pro-card {
        .position-relative {
          position: relative;
          width: 120px;
          margin: auto;
        }

        input {
          position: absolute;
          display: none;
          &:checked {
            ~ .card-name p {
              font-weight: 600;
              color: var(--primary-text-color);
            }
            ~ .card-image {
              border-color: var(--primary-text-color);
              .check {
                color: var(--white-bg-color);
                transform: scale(1);
              }
            }
          }
        }

        .card-image {
          display: block;
          margin: auto;
          height: 100px;
          width: 100px;
          border: 3px solid var(--grey-3-color);
          border-radius: 50%;
          transition: 0.2s all ease-in;
          overflow: hidden;
          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            -o-object-fit: cover;
            object-position: center;
            -o-object-position: center;
          }
          .check {
            position: absolute;
            right: 2px;
            top: 0px;
            font-size: 0.8rem;
            color: var(--white-bg-color);
            text-align: center;
            align-items: center;
            transition: 0.2s transform ease-in;
            transform: scale(0);

            background-color: var(--main-bg-color);
            height: 30px;
            width: 30px;
            line-height: 30px;
            border-radius: 50%;

            z-index: 10;
          }
        }

        .card-name p {
          color: var(--grey-1-color);
          margin-top: 10px;
          margin-bottom: 10px;
          transition: 0.2s all ease-in;
        }
      }
    }

    // Regularity
    .booking-regularity {
      .heading {
        color: var(--grey-1-color);
      }
    }

    // Appointment details
    .appointment-details {
      .heading {
        color: var(--grey-1-color);
      }
    }
  }
}

.agent-card {
  .image {
    height: 250px;
    overflow: hidden;
    transition: 0.3s ease-in all;
    &:hover {
      height: 350px;
    }
  }
  .body {
    overflow: hidden;
    transition: 0.3s ease-in all;

    span.prefix {
      font-weight: 600;
      // margin-left: 10px;
    }
    .agent-name {
      font-size: 1.2rem;
      margin: 0;
    }

    .company {
      &.name {
        margin: 0;
      }
      &.website {
        margin: 0;
      }
    }
    .email,
    .cellphone {
      display: block;
      margin: 0px;
    }
  }
  .footer {
    display: flex;
    justify-content: space-between;
    .links {
      display: inline;
      a {
        margin-left: 2px;
        margin-right: 2px;
      }
    }
  }
}

.agent-info-mini {
  .uk-card-media-top {
    padding-top: 10px;
    img {
      display: block;
      height: 150px;
      width: 150px;
      border-radius: 50%;
      object-fit: cover;
      -o-object-fit: cover;
      margin: auto;
    }
  }
  .agent-name {
    font-size: 1.2rem;
    margin: 0;
  }
  .email,
  .cellphone {
    margin: 0px;
  }
}

.note {
  font-style: italic;
  padding-left: 10px;
  font-weight: normal;
}

.place-card {
  .media {
    @media only screen and (max-width: 767px) {
      height: 250px;
    }
    .slider {
      position: relative;
      height: 100%;
      ul {
        height: 100%;
        img {
          min-height: 100%;
          object-fit: cover;
          -o-object-fit: contain;
          object-position: center;
          -o-object-position: center;
        }
      }
    }
  }
  .body {
    .address {
      font-size: 1.2rem;
      margin-bottom: 10px;
    }
    .price {
      margin: 0;
      color: var(--grey-1-color);
    }
    .options {
      display: flex;
      flex: 1 1;
      flex-flow: wrap;
      margin-top: 30px;
      p {
        font-size: 1rem;
        font-weight: 500;
        margin: 0px;
        margin-right: 10px;
        margin-bottom: 5px;
        padding: 5px;
        // background-color: var(--main-bg-color);
        border: 2px solid var(--main-bg-color);
        color: var(--main-bg-color);
        transition: 0.2s ease-in;

        &:hover {
          background-color: var(--main-bg-color);
          color: var(--main-text-color);
        }
        span {
          display: block;
          text-align: center;
          min-width: 50px;
          &.label {
            font-size: 0.7rem;
            font-weight: 400;
            text-transform: uppercase;
          }
        }
      }
    }
  }
}

.social-links {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  .list {
    list-style: none;
    li {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.2rem;
      background-color: var(--primary-text-color);
      margin: 5px 2px;
      width: 30px;
      height: 30px;
      border-radius: 2px;

      a {
        color: var(--white-bg-color);
      }
    }
  }
}

.page-footer {
  background-color: #111;
  .header {
    font-weight: 500;
    color: var(--white-bg-color);
    padding: 0;
    font-size: 1.5rem;
    line-height: 1.4;
  }
  .contact-details {
    a {
      display: block;
      &:first-child {
        margin-bottom: 10px;
      }
    }
  }
}

#newsletter-modal {
  .heading {
    color: var(--grey-2-color);
    // .span {
    //   color: var(--grey-2-color);
    // }
  }
  form {
    text-align: center;
    input {
      height: 60px;
    }
    button {
      text-transform: none;
      padding: 10px 30px;
      border-radius: 5px;
    }
  }
  p.intro {
    font-size: 1.2rem;
    .highlight {
      color: var(--main-bg-color);
    }
  }
  p.info {
    text-align: left;
    padding: 10px;
    color: var(--grey-3-color);
  }
}

.subscription-card {
  // &:hover {
  //   button {
  //     background-color: var(--main-bg-color);
  //     color: var(--white-bg-color);
  //   }
  // }
  .content {
    .badge {
      display: inline;
      padding: 10px 15px;
      color: var(--grey-6-color);
      background-color: #27ae60;
      border-radius: 2px;
    }
    .title {
      font-size: 1.2rem;
      font-weight: 600;
    }
    .subtitle {
      font-size: 1rem;
      font-weight: 600;
      text-transform: uppercase;
      margin: 0;
    }
    .price {
      font-size: 1.4rem;
      font-weight: 600;
      margin-top: 10px;
      margin-bottom: 10px;
      span {
        font-size: 1rem;
      }
    }
    .descrption-list {
      margin-top: 10px;
      .description {
        font-size: 0.9rem;
        color: var(--grey-3-color);
        margin-top: 5px;
        margin-bottom: 5px;
      }
    }
  }
  button {
    display: block;
    width: 100%;
    padding: 10px 15px;
    transition: 0.3s ease-in all;
    margin-bottom: 10px;
  }
}

// Home space
// Agents listing page

.agents-listing-page {
  .agent-info {
    .agent-brief {
      text-align: left;
      > * {
        display: block;
        margin-top: 10px;
        margin-bottom: 10px;
      }
      .email,
      .phone {
        span {
          margin-right: 20px !important;
        }
      }
      .contact {
        margin-top: 30px;
      }
    }
    .agent-bio {
      margin-top: 40px;
      .title {
        font-weight: 600;
        text-transform: uppercase;
      }
      .bio {
        text-align: justify;
      }
    }
    .agent-thumnail {
      max-height: 200px;
      max-width: 200px;
      border-radius: 100px;
      overflow: hidden;
      border: 5px solid #1e87f0;
      @media only screen and (max-width: 767px) {
        margin: auto;
      }
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        -o-object-fit: cover;
      }
    }
  }
}

.place-card-mini {
  position: relative;
  overflow: hidden;
  img.media {
    height: 200px;
    width: 100%;
    object-fit: cover;
    -o-object-fit: cover;
    object-position: center;
    -o-object-position: center;
    @media only screen and (max-width: 767px) {
      height: 250px;
    }
  }
  .body {
    padding: 10px;
    .address {
      font-size: 0.9rem;
      margin-bottom: 0px;
      font-weight: 300;
    }
    .price {
      font-size: 1rem;
      margin: 0;
    }
    .options {
      display: flex;
      flex: 1 1;
      flex-flow: wrap;
      margin-top: 15px;
      p {
        font-size: 0.8rem;
        font-weight: 500;
        margin: 0px;
        margin-right: 5px;
        margin-bottom: 5px;
        padding: 5px;
        border: 1px solid var(--main-bg-color);
        transition: 0.2s ease-in;
        span {
          display: block;
          text-align: center;
          min-width: 20px;
        }
        .label {
          font-size: 0.7rem;
          font-weight: 400;
          text-transform: uppercase;
        }
      }
    }
  }
}

// Contact agent modal
#modal-contact-agent {
  .title {
    font-size: 1.2rem;
    font-weight: 600;
    text-transform: uppercase;
  }
  .radio-inputs {
    label {
      input {
        margin-right: 10px;
      }
    }
  }
  .footer {
    button:last-child {
      margin-left: 10px;
    }
  }
}

.home-space-header {
  .search-tabs {
    ul {
      margin: 0;
      &::before {
        content: none !important;
      }
      li {
        background-color: var(--white-bg-color);
        padding: 0;
        a {
          padding: 10px 20px;
          width: 100%;
          font-weight: 600;
        }
        &.uk-active {
          background-color: var(--primary-text-color);
          a {
            color: var(--main-text-color);
          }
        }
      }
    }
  }
  .search {
    background-color: var(--white-bg-color);
    border-radius: 0 !important;
    margin-top: 0px !important;
    padding: 20px;
    box-shadow: 1px -2px 9px -2px rgba(47, 128, 237, 0.75);
    -webkit-box-shadow: 1px -2px 9px -2px rgba(47, 128, 237, 0.75);
    -moz-box-shadow: 1px -2px 9px -2px rgba(47, 128, 237, 0.75);
  }
}
.home-space-header {
  .search {
    border-radius: 0px;
    margin-top: 80px;
    margin-bottom: 80px;
    form {
      width: 100%;
    }
  }
}

// View place
#view-property {
  .uk-modal-header {
    height: 70px !important;
  }
  .uk-modal-body {
    height: calc(100vh - 70px);
    max-height: 100% !important;
  }
}
.view-place-card {
  height: 500px;
  .content {
    .media {
      position: relative;
    }
    height: 100%;
  }
  .description {
    p {
      margin-top: 40px;
    }
  }
}
.view-card-thumbnails {
  position: absolute;
  z-index: 1000;
  overflow: hidden;
  margin-top: 20px;
  bottom: 0px;
  background-color: var(--grey-1-color);
  padding: 10px;
  width: calc(100% - 40px) !important;
  li {
    margin: 2px;
    img {
      height: 70px;
      width: 70px;
      object-fit: cover;
      -o-object-fit: cover;
      object-position: center;
      border-radius: 5px;
    }
  }
}

.place-search {
  position: relative;
  width: 100%;
}
.search-autocomplete-results {
  position: absolute !important;
  width: 100%;
  max-height: 300px !important;
  z-index: 10000;
  background-color: var(--white-bg-color);
  // background-color: var(--grey-1-color);
  left: 50%;
  transform: translateX(-50%);
  top: calc(100% + 10px);
  border-radius: 0px;
  overflow: auto;
  list-style: none;
  margin: 0;
  padding: 0;
  li {
    height: 50px;
    line-height: 50px;
    padding-left: 30px;
    padding-right: 30px;
  }

  & li[data-focus="true"] {
    background-color: var(--grey-6-color);
    color: var(--grey-1-color);
    cursor: pointer;
  }
  & li:active {
    background-color: #2977f5;
    color: white;
  }
}

.near-by {
  display: flex;
  padding: 10px !important;
  border-radius: 5px;
  transition: 0.3s ease-in-out all;
  margin-bottom: 10px !important;
  &:hover {
    cursor: pointer;
    .body {
      .title,
      .description {
        color: var(--grey-1-color);
      }
    }
  }
  .image {
    height: 80px;
    width: 80px;
    border-radius: 5px;
    overflow: hidden;
    img {
      object-fit: cover;
      -o-oobject-fit: cover;
      object-position: center;
      -o-object-position: center;
      height: 100%;
      width: 100%;
    }
  }
  .body {
    padding: 10px;
    transition: 0.3s ease-in-out all;

    .title {
      font-size: 1.2rem;
      font-weight: 600;
      margin: 0;
      transition: 0.3s ease-in-out all;
    }
    .description {
      margin: 0;
      transition: 0.3s ease-in-out all;
    }
  }
}

.place-search-card {
  .image {
    height: 200px;
    overflow: hidden;
    img {
      object-fit: cover;
      -o-object-fit: cover;
      object-position: center;
      -o-object-position: center;
      width: 100%;
      height: 100%;
    }
  }
  .body {
    .title {
      font-size: 1rem;
      margin-top: 0;
      margin-bottom: 0;
    }
    .price {
      font-size: 1rem;
      margin-top: 0;
      margin-bottom: 0;
    }
    .options {
      display: flex;
      flex: 1 1;
      flex-flow: wrap;
      margin-top: 10px;
      margin-bottom: 20px;
      p {
        font-size: 1rem;
        font-weight: 500;
        margin: 0px;
        margin-right: 10px;
        margin-bottom: 5px;
        padding: 5px;
        // background-color: var(--main-bg-color);
        border: 2px solid var(--main-bg-color);
        color: var(--main-bg-color);
        transition: 0.2s ease-in;

        &:hover {
          background-color: var(--main-bg-color);
          color: var(--main-text-color);
        }
        span {
          display: block;
          text-align: center;
          min-width: 50px;
          &.label {
            font-size: 0.7rem;
            font-weight: 400;
            text-transform: uppercase;
          }
        }
      }
    }
    .extra-info {
      transition: 0.3s ease-in-out all;
      height: 0;
      overflow: hidden;
      &.show {
        height: 200px;
      }
    }
    .showMoreBtn {
      // color: var(--primary-text-color);
      span {
        margin-left: 10px;
      }
    }
  }
}
